/*
@contract listing-card {
  min-height: MinHeight
  padding: Padding
  badge:{
    star: {
      color: Color
    }
  }
  border-color: Color
}
*/

.ctaButtons {
  --icon-button-width: 100%;
  --button-font-size: var(--font-size-body);
  --button-padding: 0;
  --button-gap: var(--space-1x);
  display: flex;
  margin-top: var(--space-4x);
  padding-left: var(--space-4x);
  padding-right: var(--space-4x);
  gap: var(--space-2x);
  position: relative;
  z-index: var(--z-index-2x);
  --button-rounded-border-radius: var(--border-radius-full);
}

.price {
  font-weight: var(--font-weight-semibold);
  font-size: var(--font-size-lg);
  color: var(--color-black);
  flex: 1;
}

.bedAndPriceText {
  padding-right: var(--space-0-5x);
}

.boldedPriceText {
  font-weight: var(--font-weight-semibold);
  color: var(--color-gray-500);
}

.bedAndPriceTextBullet {
  padding: 0 var(--space-0-5x);
}

.basicDetails {
  height: 43px;
  display: flex;
  padding-top: var(--space-4x);
  padding-left: var(--space-4x);
  padding-right: var(--space-4x);
  position: relative;
}

.shareAndSaveButtonWrapper {
  display: flex;
  gap: var(--space-1x);
  z-index: var(--z-index-1x);
  position: absolute;
  top: var(--space-2-5x);
  right: var(--space-3x);
}

.middle {
  inset: 0;
  padding-left: var(--space-4x);
  padding-right: var(--space-4x);
  display: block;
  height: auto;
}

.absoluteLink {
  text-decoration: none;
  color: var(--color-gray-500);
  flex: auto;
}

.absoluteLink::after {
  content: '';
  position: absolute;
  inset: 0;
}

.bottom {
  --anchor-display: flex;
  --anchor-placement: center;
  --anchor-gap: var(--space-1x);
  --anchor-position: relative;
  --anchor-z-index: var(--z-index-2x);
  --anchor-padding: 0 var(--space-4x) 0 0;
  display: flex;
  justify-content: space-between;
  padding-left: var(--space-4x);
  padding-top: var(--space-0-5x);
}

.ctas {
  display: flex;
  justify-content: space-between;
  padding-left: var(--space-4x);
  padding-right: var(--space-4x);
  gap: var(--space-2x);
  position: relative;
  z-index: 1;
}

.ctaIcon {
  display: none;

  @media (--screen-xs) {
    display: block;
  }
}

.ctaButton {
  font-size: var(--font-size-body);
  gap: var(--space-2x);
  padding-left: var(--space-2x);
  padding-right: var(--space-2x);
}

.card {
  --rating-star-size: var(--font-size-md);
  --rating-size: var(--front-size-body);
  --bookmark-filled-background-color: var(
    --listing-card-bookmark-filled-background-color
  );
  display: flex;
  flex-direction: column;
  background: var(--color-white);
  border-radius: var(--border-radius-5x);
  width: 100%;
  padding: var(--listing-card-padding);
  color: var(--color-gray-500);
  position: relative;
  z-index: var(--z-index-0);
  font-size: var(--font-size-body);
  min-height: var(--listing-card-min-height);
  border: 1px solid var(--color-gray-300);
}

.activeFromMap {
  transition: box-shadow 0.3s ease-in-out;
  border-radius: var(--space-1x);
  box-shadow: var(--search-results-li-highlight-box-shadow);
}

.strong {
  font-weight: bold;
  color: var(--color-black);
}

.badge {
  position: absolute;
  top: 0;
  left: 0;
  z-index: var(--z-index-2x);
  /* stylelint-disable-next-line declaration-property-value-disallowed-list */
  margin-top: 9px;
  margin-left: 9px;
  pointer-events: none;
  background-color: var(--color-lime-300);
  color: var(--color-black);
  font-weight: var(--font-weight-semibold);
  border-radius: var(--border-radius-full);
  padding: var(--space-0-5x) var(--space-2x);
}

.topLeftBadgeContainer {
  position: absolute;
  top: 0;
  left: 0;
  z-index: var(--z-index-2x);
  margin: 9px 0 0 var(--space-3-5x);
  display: flex;
  gap: var(--space-1-5x);
  align-items: flex-start;
  flex-direction: column;

  @media (--screen-xs) {
    flex-direction: row;
    align-items: center;
  }
}

.topPromotionText {
  pointer-events: none;
  color: var(--color-white);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-semibold);
  line-height: var(--space-4-5x);
  text-shadow: 0 var(--space-0-5x) var(--space-0-5x) rgba(0 0 0 / 35%);
  padding-left: 6px;

  @media (--screen-xs) {
    padding-left: 0;
  }
}

.dealsBadgeOnBottom {
  position: unset;
  margin: auto;
}

.carouselSection {
  position: relative;
  z-index: var(--z-index-2x);
}

.listWrapper {
  position: absolute;
  display: flex;
  bottom: 0;
  padding-left: 9px;
  z-index: var(--z-index-2x);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-semibold);
  color: var(--color-gray-500);
  width: 100%;
  gap: var(--space-1x);
  align-items: flex-end;
  justify-content: space-between;
  pointer-events: none;

  .carouselBadgesLeft,
  .carouselBadgesRightCategoryBadge {
    display: flex;
    margin-bottom: 9px;
    gap: var(--space-1x);
    pointer-events: auto;
  }

  .carouselBadgesRightCategoryBadge {
    padding-right: var(--space-4x);
  }

  .carouselBadgesRight {
    display: flex;
    align-self: flex-end;
    justify-self: flex-end;
    flex: 0 4 auto;
    max-width: 100%;
    overflow: hidden;
    pointer-events: auto;
    --icon-button-width: 100%;
  }
}

.listWrapperPropertyComparison {
  padding-left: var(--space-4x);
  bottom: var(--space-1x);
}

.promotionText {
  border-radius: var(--border-radius-full);
  background-color: var(--listing-card-category-badge-background-color);
  padding: var(--space-0-5x) var(--space-2x);
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  height: 22px;
}

.badgeIcon {
  color: var(--listing-card-badge-star-color);
  margin-right: var(--space-1x);
  width: 13px;
  height: 13px;
}

.carouselIcons {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  margin: 3px 9px 0 var(--space-8x);
  --icon-cta-padding: 0;
  --icon-cta-z-index: var(--z-index-2x);
  --icon-cta-margin: var(--space-1x) 0 0 var(--space-2x);
}

.carouselIconsPropertyComparison {
  margin: 5px var(--space-4x) 0 var(--space-8x);
}

.carousel {
  position: relative;
  height: 240px;

  /* To account for the 1 pixel border this needs to be 1px more then card border radius */
  border-radius: 9px 9px 0 0;

  @media (--screen-md) {
    min-width: 100%;
    height: 100%;
  }
}

.listingDescription {
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  /* stylelint-disable value-no-vendor-prefix */
  display: -webkit-box;
  overflow: hidden;
}
